import { useRouteError } from "react-router-dom";
import PropTypes from 'prop-types';

export default function ErrorPage({ errorId }) {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, da ist was schiefgelaufen. Error-Id: {errorId} </p>
    </div>
  );
}

ErrorPage.propTypes = {
    errorId: PropTypes.number,
  };