import actionBarStyles from './ActionBar.module.css';
import PropTypes from 'prop-types';
import cn from 'classnames';

export default function ActionBar({ onSubmit, onDeselect, canSubmit, canDeselect, className }) {
  return (
    <div className={cn(actionBarStyles.actionBar, className)}>
    <button className={actionBarStyles.submitButton} onClick={onDeselect} disabled={!canDeselect}>Alles abwählen</button>  
    <button className={actionBarStyles.submitButton} onClick={onSubmit} disabled={!canSubmit}>Prüfen</button>
    </div>
  );
}

ActionBar.defaultProps = {
  canSubmit: false,
  canDeselect: false,
  className: null
};

ActionBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  onDeselect: PropTypes.func.isRequired,
  canDeselect: PropTypes.bool.isRequired,
  className: PropTypes.string
};