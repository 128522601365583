import PropTypes from 'prop-types';
import headerStyles from './Header.module.css';

export default function Header({ content }){
    return (
        <header className={headerStyles.appHeader}>
            <div className={headerStyles.logo}>
                <a href="/"><img src="/logo.png" alt="Denken InBegriffen Logo" title="Denken InBegriffen Logo" /></a>
                <h1 className={headerStyles.logoText}>
                <span className={headerStyles.connecting}>Denken</span>
                <span className={headerStyles.included}><span className={headerStyles.in}>In</span><span className={headerStyles.cluded}>Begriffen</span></span>
                </h1>
            </div>
            { content }
        </header>);
}

Header.propTypes = {
    content: PropTypes.object,
};