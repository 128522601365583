import PropTypes from 'prop-types';
import IconButton from '../IconButton/IconButton';

import helpButtonStyles from './HelpButton.module.css';

const HelpButton = ({ onHelpButtonClick }) => {

    return (
    <IconButton
      buttonStyleClass={helpButtonStyles.help}
      buttonText="Wie funktioniert es?"
      iconName="icon-help"
      onClick={onHelpButtonClick}
    />
    );
}

export default HelpButton;

  HelpButton.propTypes = {
    onHelpButtonClick: PropTypes.func.isRequired,
  };