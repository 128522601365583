import PropTypes from 'prop-types';

import IconButton from '../IconButton/IconButton';
import helpToolTipStyles from './HelpToolTip.module.css';

const HelpToolTip = ({ onHelpToolTipCloseClick }) => {
    return (
      <div role="dialog" aria-modal="true" className={helpToolTipStyles.modalContainer}>
        <div className={helpToolTipStyles.modalOverlay}></div>
        <div className={helpToolTipStyles.helpToolTip}>
            <IconButton
    buttonStyleClass={helpToolTipStyles.close}
    buttonText="Schliessen"
    iconName="icon-close"
    onClick={onHelpToolTipCloseClick}
  />
        <h2>So funktionierts</h2>
        Sortiere die 16 Begriffe in vier thematische Gruppen. Einige Worte können in mehrere Gruppen passen - aber es gibt nur eine Zuordnung, bei der jede Gruppe genau vier Worte enthält. 
        <br /><br />
        Wenn Du denkst, Du hast eine Vierergruppe gefunden, klicke auf die vier Begriffe und danach auf &quot;Pr&uuml;fen&quot;. Wenn Du eine Gruppe herausgefunden hast, siehst Du den Begriff, der die vier Worte verbindet.
        <br /><br />
        Jeder Gruppe ist eine Farbe zugeordnet, die sich beim Lösen offenbart. Die Farbe zeigt den Schwierigkeitsgrad der Gruppe an: <br />Von <span className={helpToolTipStyles.yellow}>gelb</span> (einfach) über <span className={helpToolTipStyles.green}>grün</span> und <span className={helpToolTipStyles.blue}>blau</span> zu <span className={helpToolTipStyles.violet}>lila</span> (schwierig).
        <br /><br />
        Du hast sechs Versuche, um die richtige Lösung zu finden. Nach dem sechsten erfolglosen Versuch werden die Gruppen angezeigt.    
    </div>
    </div>
    );
};

export default HelpToolTip;
  
  HelpToolTip.propTypes = {
    onHelpToolTipCloseClick: PropTypes.func.isRequired,
  };