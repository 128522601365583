import cellStyles from './BoardCell.module.css';
import cn from 'classnames';
import PropTypes from 'prop-types';

export default function BoardCell({ itemText, isSelected, isInvalid, rowIndex, cellIndex, onCellClick }) {

  function handlePointerDown(event) {
    event.target.classList.add(cellStyles.touched);
  }

  return (
    <div 
    key={`${rowIndex}-${cellIndex}`} 
    className={cn('item', cellStyles.item, 
      { [cellStyles.text10]: itemText.length >= 10 && itemText.length < 12 }, 
      { [cellStyles.text12]: itemText.length >= 12 && itemText.length < 15 }, 
      { [cellStyles.text15]: itemText.length >= 15 && itemText.length < 20 }, 
      { [cellStyles.text20]: itemText.length >= 20 }, 
      { [cellStyles.selected]: isSelected }, 
      { [cellStyles.invalidShake]: isInvalid }, 
      { [cellStyles.inactive]: isInvalid })} 
    onPointerDown={handlePointerDown} 
    onClick={() => onCellClick(itemText)}>
      {itemText}
    </div>
  );
}

BoardCell.defaultProps = {
  isSelected: false,
  isInvalid: false
};

BoardCell.propTypes = {
  itemText: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  rowIndex: PropTypes.number.isRequired,
  cellIndex: PropTypes.number.isRequired,
  onCellClick: PropTypes.func.isRequired
};